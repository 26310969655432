import type { INewJustInTimeActivity } from "@/api/Activities";
import { addJustInTimeActivity } from "@/api/Activities";
import ErrorMessage from "@components/ErrorMessage/ErrorMessage";
import { FilledButton } from "@components/Button/Button";
import Form from "@/components/Form/Form";
import InputField from "@/components/InputField/InputField";
import { Loading } from "@components/Loading/Loading";
import TextArea from "@/components/TextArea/TextArea";
import CheckmarkIcon from "@components/icons/CheckmarkIcon";
import { deducedError } from "@/Utils/ErrorUtils";
import { generateRandomUUID } from "@/Utils/UniqueId";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";

interface IPostRegisterActivityFormData {
  description: string;
  title: string;
}

const PostRegisterActivity = ({
  onSuccess,
  patientId,
  routeId,
  visitId,
}: {
  onSuccess: () => void;
  patientId: string;
  routeId: string;
  visitId: string;
}) => {
  const {
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm<IPostRegisterActivityFormData>();

  const { mutate, isPending: isAdding } = useMutation({
    mutationFn: ({ newActivity }: { newActivity: INewJustInTimeActivity }) =>
      addJustInTimeActivity(newActivity),
    onError: (error) => {
      setError("root.server", {
        message: deducedError(error),
      });
      // Reset `isDirty` to support only showing server error when the form is not changed.
      reset(getValues(), {
        keepErrors: true,
        keepIsSubmitted: true,
        keepTouched: true,
        keepIsValid: true,
        keepSubmitCount: true,
      });
    },
    onSuccess: () => {
      reset();
      return onSuccess();
    },
  });

  return (
    <Form
      onSubmit={handleSubmit((formData) => {
        const newActivity: INewJustInTimeActivity = {
          activityId: generateRandomUUID(),
          patientId,
          title: formData.title,
          description: formData.description,
          visitId,
          routeId,
        };
        mutate({ newActivity });
      })}
    >
      {isAdding ? (
        <Loading message="Efterregistrerar aktivitet" />
      ) : (
        <>
          {errors.root?.server?.message && !isDirty ? (
            <ErrorMessage message={errors.root.server.message} />
          ) : null}
          <InputField
            label="Namn"
            errorMessage={errors.title?.message}
            {...register(`title`, {
              required: {
                value: true,
                message: "Aktiviteten behöver ett namn.",
              },
            })}
          />
          <TextArea
            label="Beskrivning (valfri)"
            errorMessage={errors.description?.message}
            {...register(`description`)}
          />
          <FilledButton type="submit" width="fill-container">
            <CheckmarkIcon />
            Lägg till aktiviteten
          </FilledButton>
        </>
      )}
    </Form>
  );
};

export { PostRegisterActivity };
