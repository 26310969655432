import type { ComponentProps } from "react";
import styles from "./DragAndDrop.module.scss";
import {
  ListBox as AriaListBox,
  ListBoxItem as AriaListBoxItem,
} from "react-aria-components";

export const ListBox = AriaListBox;
export const ListBoxItem = (
  props: ComponentProps<typeof AriaListBoxItem> & {
    textValue: Required<ComponentProps<typeof AriaListBoxItem>["textValue"]>;
  },
) => <AriaListBoxItem className={styles.option} {...props} />;
